import React from 'react'
import { size } from 'lodash'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faBook } from '@fortawesome/pro-solid-svg-icons'

import {
  StyledImg,
  DefinitionWrapper,
  Container,
  Type,
  Title,
  Description,
} from './styles'

const Definition = ({ backgroundColor, optionalImage, definition }) => (
  <Container thm={backgroundColor}>
    {optionalImage && <StyledImg sizes={optionalImage.sizes} />}
    <DefinitionWrapper
      thm={backgroundColor}
      optionalImage={size(optionalImage)}
    >
      <Type thm={backgroundColor} fontFamily="monospace">
        Term
      </Type>
      {definition && (
        <div>
          <Title thm={backgroundColor} fontFamily="serif">
            <FontAwesomeIcon
              fixedWidth
              icon={faBook}
              size="1x"
              style={{ marginRight: '0.75rem' }}
            />
            {definition.word}
          </Title>
          <Description
            thm={backgroundColor}
            dangerouslySetInnerHTML={{
              __html: definition.shortDescription.childMarkdownRemark.html,
            }}
          />
        </div>
      )}
    </DefinitionWrapper>
  </Container>
)

export default Definition
