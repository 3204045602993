import React from 'react'
import { camelCase } from 'lodash'

import { Container, Text } from './styles'

const PullQuote = ({
  font,
  textColor,
  backgroundColor,
  textSize,
  border,
  fullWidth,
  fullHeight,
  fontStyle,
  textAlignment,
  text,
}) => (
  <Container
    fullWidth={fullWidth}
    fullHeight={fullHeight}
    color={camelCase(textColor === 'Red' ? 'Brand Orange' : textColor)}
    bg={camelCase(backgroundColor === 'Red' ? 'Brand Orange' : backgroundColor)}
    border={border}
    size={textSize}
    font={font}
  >
    <Text
      fontFamily={camelCase(font)}
      fontStyle={camelCase(fontStyle)}
      textAlign={camelCase(textAlignment)}
      size={textSize}
      font={font}
      fullWidth={fullWidth}
    >
      {text.text}
    </Text>
  </Container>
)

export default PullQuote
