import styled from 'styled-components'
import { fontFamily } from 'styled-system'
import Img from 'gatsby-image'

export const StyledImg = styled(Img)`
  width: 100%;
  height: 100vh;
  z-index: 0;
`

export const ImageWrap = styled.div`
  height: 100%;
  position: relative;
`
export const DefinitionWrapper = styled.div`
  position: ${props => (props.optionalImage ? 'absolute' : 'initial')};
  bottom: 3.5rem;
  left: 1rem;
  right: 1rem;
  padding: 1rem;
  background: ${props =>
    props.thm === 'Dark' ? props.theme.colors.black : props.theme.colors.white};
  @media only screen and (max-width: 62rem) {
    padding-right: 3rem;
  }
`

export const Type = styled.h3`
  font-weight: 400;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin-left: 3rem;
  margin-right: 0.5rem;
  ${fontFamily};
  color: ${props =>
    props.thm === 'Dark' ? props.theme.colors.white : props.theme.colors.black};
`

export const Title = styled.h2`
  font-weight: 300;
  font-size: 1.875rem;
  margin-left: 3rem;
  margin-right: 0.5rem;
  text-indent: -1.75em;
  ${fontFamily};
  color: ${props =>
    props.thm === 'Dark' ? props.theme.colors.white : props.theme.colors.black};
  @media only screen and (max-width: 32rem) {
    font-size: 1.5rem;
  }
`

export const Description = styled.div`
  margin-left: 3rem;
  margin-right: 0.5rem;
  line-height: 1.5;
  max-width: 28em;
  color: ${props =>
    props.thm === 'Dark' ? props.theme.colors.white : props.theme.colors.black};
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding-bottom: 2.5rem;
  background: ${props =>
    props.thm === 'Dark' ? props.theme.colors.black : props.theme.colors.white};
`
