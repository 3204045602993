import React from 'react'
import { graphql } from 'gatsby'
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from 'react-scroll'

// import Helmet from 'react-helmet'
// import Link from 'gatsby-link'
import { get, mixin, every, sampleSize, indexOf } from 'lodash'
// import { StickyContainer, Sticky } from 'react-sticky'
import ScrollLock from 'react-scrolllock'

import ChangeTitle from 'containers/change-title'
import Headline from '../../components/Headline/Headline'
import Related from '../../components/Related/Related'
import Sidebar from '../../components/Sidebar/Sidebar'
import Markdown from '../../components/ContentComponents/Markdown'
import PullQuote from '../../components/ContentComponents/PullQuote'
import ImageOrVideo from '../../components/ContentComponents/ImageOrVideo'
import { Container, Content, Block, ToggleBack } from './styles'

function chunkBy(array, predicate) {
  return array.reduce((prev, current) => {
    if (
      prev.length === 0 ||
      predicate(current) ||
      every(prev[prev.length - 1], predicate)
    ) {
      prev.push([current])
    } else {
      prev[prev.length - 1].push(current)
    }
    return prev
  }, [])
}
mixin({ chunkBy }, { chain: false })

class ContextTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = { sidebarExpanded: false }
    this.toggleSidebar = this.toggleSidebar.bind(this)
    this.scrollTo = this.scrollTo.bind(this)
  }

  processContent(post) {
    const chunked = chunkBy(post.content, o => o.fullWidth)
    return chunked
  }

  indexOfSections(array) {
    const num = []
    array.map(item => {
      if (!item[0].fullWidth) num.push(indexOf(array, item))
    })
    return num
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true
  }

  scrollTo(sidebar) {
    scroller.scrollTo(sidebar, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
    })
  }

  toggleSidebar(sidebar) {
    this.setState({ sidebarExpanded: !this.state.sidebarExpanded }, () => {
      if (this.state.sidebarExpanded && sidebar) this.scrollTo(sidebar)
    })
  }

  render() {
    const post = get(this.props, 'data.contentfulContext')
    const data = get(this.props, 'data')
    // const changeTitle = get(this.props, 'changeTitle')
    const contentBlocks = this.processContent(post)
    const headline = get(this.props, 'data.contentfulContext.headline')
    const allContent = data.allContentfulStory.edges.concat(
      data.allContentfulContext.edges
    )
    const sectionIndexes = this.indexOfSections(contentBlocks)
    // const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    // changeTitle(post.title)

    return (
      <Container expand={this.state.sidebarExpanded}>
        <ChangeTitle title={post.title} />
        {this.state.sidebarExpanded && (
          <div>
            <ScrollLock />
            <ToggleBack onClick={this.toggleSidebar}>
              <span>Back to Content</span>
            </ToggleBack>
          </div>
        )}

        <div className="wrapper">
          <Headline
            headline={headline}
            type={post.type}
            title={post.title}
            author={post.author}
          />

          <div>
            {contentBlocks.map((value, index) => (
              <div key={index}>
                {value[0].fullWidth ? (
                  <Block
                    blur={this.state.sidebarExpanded}
                    style={{
                      width: '100%',
                      display: 'flex',
                      position: 'relative',
                    }}
                  >
                    {value.map(item => (
                      <div
                        key={item.id}
                        style={{
                          width: '100%',
                        }}
                      >
                        {item.__typename ===
                          'ContentfulComponentBodyMarkdown' && (
                          <Markdown {...item} />
                        )}
                        {item.__typename ===
                          'ContentfulComponentBodyPullquote' && (
                          <PullQuote {...item} />
                        )}
                        {item.__typename ===
                          'ContentfulComponentBodyImageOrVideo' && (
                          <ImageOrVideo {...item} />
                        )}
                      </div>
                    ))}
                  </Block>
                ) : (
                  <div
                    className="block"
                    style={{
                      position: 'relative',
                    }}
                  >
                    <Content expand={this.state.sidebarExpanded}>
                      <Sidebar
                        index={indexOf(sectionIndexes, index)}
                        toggle={this.toggleSidebar}
                        isExpanded={this.state.sidebarExpanded}
                        {...post}
                      />
                      <Block blur={this.state.sidebarExpanded}>
                        {value.map(item => (
                          <div key={item.id}>
                            {item.__typename ===
                              'ContentfulComponentBodyMarkdown' && (
                              <Markdown {...item} />
                            )}
                            {item.__typename ===
                              'ContentfulComponentBodyPullquote' && (
                              <PullQuote {...item} />
                            )}
                            {item.__typename ===
                              'ContentfulComponentBodyImageOrVideo' && (
                              <ImageOrVideo {...item} />
                            )}
                          </div>
                        ))}
                      </Block>
                    </Content>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <Related posts={sampleSize(allContent, 3)} />
      </Container>
    )
  }
}

export default ContextTemplate

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query ContextPostBySlug($slug: String!) {
    contentfulContext(slug: { eq: $slug }) {
      title
      author {
        id
        name
        title
      }
      type
      headline {
        id
        height
        textboxWidth
        textSize
        textAlignment
        textboxPosition
        color
        backgroundImage {
          id
          sizes(maxWidth: 1800) {
            ...GatsbyContentfulSizes_withWebp
          }
        }
      }
      content {
        __typename
        ... on ContentfulComponentBodyPullquote {
          id
          font
          textColor
          textAlignment
          backgroundColor
          textSize
          fullWidth
          fullHeight
          border
          fontStyle
          text {
            id
            text
          }
        }
        ... on ContentfulComponentBodyMarkdown {
          id
          text {
            id
            childMarkdownRemark {
              id
              html
            }
          }
        }
        ... on ContentfulComponentBodyImageOrVideo {
          id
          imagePadding
          image {
            id
            sizes(maxWidth: 1800) {
              ...GatsbyContentfulSizes_withWebp
            }
          }
          optionalCaption {
            id
            childMarkdownRemark {
              id
              html
            }
          }
          optionalCredit {
            id
            name
          }
          video
          backgroundVideo
          imageWidth
          imagePosition
          fullWidth
        }
      }
      sidebarContent {
        __typename
        ... on ContentfulComponentSidebarMap {
          backgroundColor
          mapStyle
          zoom
          locations {
            id
            location {
              lon
              lat
            }
          }
        }
        ... on ContentfulComponentSidebarBreak {
          id
        }
        ... on ContentfulComponentSidebarImageOrVideo {
          title
          fullHeightCrop
          backgroundColor
          backgroundVideo
          image {
            id
            sizes(maxWidth: 1800) {
              ...GatsbyContentfulSizes_withWebp
            }
          }
        }
        ... on ContentfulComponentSidebarAudio {
          id
          title
          description {
            id
            childMarkdownRemark {
              id
              html
            }
          }
          backgroundColor
          optionalImage {
            id
            sizes(maxWidth: 1800) {
              ...GatsbyContentfulSizes_withWebp
            }
          }
          audioFile {
            id
            file {
              url
            }
          }
        }
        ... on ContentfulComponentSidebarDefinition {
          id
          title
          backgroundColor
          optionalImage {
            id
            sizes(maxWidth: 1800) {
              ...GatsbyContentfulSizes_withWebp
            }
          }
          definition {
            id
            word
            shortDescription {
              id
              childMarkdownRemark {
                id
                html
              }
            }
          }
        }
        ... on ContentfulComponentSidebarExternalLink {
          id
          title
          description {
            id
            childMarkdownRemark {
              id
              html
            }
          }
          backgroundColor
          externalLink
          optionalImage {
            id
            sizes(maxWidth: 1800) {
              ...GatsbyContentfulSizes_withWebp
            }
          }
        }
        ... on ContentfulComponentSidebarInternalLink {
          id
          title
          description {
            id
            childMarkdownRemark {
              id
              html
            }
          }
          backgroundColor
          internalLink {
            id
            type
            slug
            title
            thumbnailImage {
              id
              sizes(maxWidth: 1800) {
                ...GatsbyContentfulSizes_withWebp
              }
            }
          }
        }
      }
    }
    allContentfulStory {
      edges {
        node {
          id
          title
          slug
          type
          subject {
            name
            age
          }
          thumbnailImage {
            id
            sizes(maxWidth: 1800) {
              ...GatsbyContentfulSizes_withWebp
            }
          }
          published
        }
      }
    }
    allContentfulContext {
      edges {
        node {
          id
          title
          slug
          type
          author {
            name
          }
          thumbnailImage {
            id
            sizes(maxWidth: 1800) {
              ...GatsbyContentfulSizes_withWebp
            }
          }
          published
        }
      }
    }
  }
`
