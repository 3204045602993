import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import 'react-rangeslider/lib/index.css'

import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  faPlayCircle,
  faPauseCircle,
} from '@fortawesome/fontawesome-free-solid'
import { faVolumeMute, faVolumeUp } from '@fortawesome/pro-solid-svg-icons'

import { size } from 'lodash'

import {
  StyledImg,
  AudioWrapper,
  Container,
  Type,
  Title,
  Description,
  IconButton,
  StyledAudio,
  AudioSeek,
  StyledMute,
  VolumeSlider,
  VolumeWrapper,
} from './styles'

class Audio extends Component {
  constructor(props) {
    super(props)
    this.state = {
      url: props.audioFile.file.url,
      playing: false,
      volume: 0.5,
      muted: false,
      played: 0,
    }
    this.playPause = this.playPause.bind(this)
    this.stop = this.stop.bind(this)
    this.setVolume = this.setVolume.bind(this)
    this.toggleMuted = this.toggleMuted.bind(this)
    this.onPlay = this.onPlay.bind(this)
    this.onPause = this.onPause.bind(this)
    this.onSeekMouseDown = this.onSeekMouseDown.bind(this)
    this.onSeekChange = this.onSeekChange.bind(this)
    this.onSeekMouseUp = this.onSeekMouseUp.bind(this)
    this.onProgress = this.onProgress.bind(this)
    this.onEnded = this.onEnded.bind(this)
    this.ref = this.ref.bind(this)
  }

  onPlay() {
    this.setState({ playing: true })
  }
  onPause() {
    this.setState({ playing: false })
  }
  onSeekMouseDown() {
    this.setState({ seeking: true })
  }
  onSeekChange(value) {
    this.setState({ played: parseFloat(value) })
  }
  onSeekMouseUp() {
    this.setState({ seeking: false })
    this.player.seekTo(this.state.played)
  }
  onProgress(state) {
    if (!this.state.seeking) {
      this.setState(state)
    }
  }
  onEnded() {
    this.setState({ playing: this.state.loop })
  }
  setVolume(value) {
    this.setState({ volume: parseFloat(value) })
  }
  stop() {
    this.setState({ url: null, playing: false })
  }
  playPause() {
    this.setState({ playing: !this.state.playing })
  }

  toggleMuted() {
    this.setState({ muted: !this.state.muted })
  }
  ref(player) {
    this.player = player
  }

  render() {
    const { url, playing, volume, muted, played } = this.state

    const { title, description, backgroundColor, optionalImage } = this.props

    return (
      <Container thm={backgroundColor}>
        {optionalImage && <StyledImg sizes={optionalImage.sizes} />}

        <AudioWrapper thm={backgroundColor} optionalImage={size(optionalImage)}>
          <ReactPlayer
            ref={this.ref}
            url={url}
            playing={playing}
            volume={volume}
            muted={muted}
            onPlay={this.onPlay}
            onPause={this.onPause}
            onEnded={this.onEnded}
            onProgress={this.onProgress}
            style={{ display: 'none' }}
          />
          <StyledAudio>
            <IconButton thm={backgroundColor} onClick={this.playPause}>
              {playing ? (
                <FontAwesomeIcon icon={faPauseCircle} size="1x" />
              ) : (
                <FontAwesomeIcon icon={faPlayCircle} size="1x" />
              )}
            </IconButton>
            <AudioSeek
              value={played}
              style={{ width: '100%' }}
              min={0}
              max={1}
              tooltip={false}
              step={0.001}
              onChangeStart={this.onSeekMouseDown}
              onChange={this.onSeekChange}
              onChangeComplete={this.onSeekMouseUp}
              thm={backgroundColor}
            />
            <VolumeWrapper thm={backgroundColor}>
              <VolumeSlider
                value={muted ? 0 : volume}
                min={0}
                max={1}
                orientation="vertical"
                tooltip={false}
                step={0.001}
                onChange={this.setVolume}
                thm={backgroundColor}
                className="volume-slider"
              />
              <StyledMute thm={backgroundColor} onClick={this.toggleMuted}>
                {muted || volume === 0 ? (
                  <FontAwesomeIcon fixedWidth icon={faVolumeMute} size="1x" />
                ) : (
                  <FontAwesomeIcon fixedWidth icon={faVolumeUp} size="1x" />
                )}
              </StyledMute>
            </VolumeWrapper>
          </StyledAudio>

          <Type fontFamily="monospace" thm={backgroundColor}>
            Audio
          </Type>

          {title && (
            <Title fontFamily="serif" thm={backgroundColor}>
              {title}
            </Title>
          )}
          {description && (
            <Description
              thm={backgroundColor}
              dangerouslySetInnerHTML={{
                __html: description.childMarkdownRemark.html,
              }}
            />
          )}
        </AudioWrapper>
      </Container>
    )
  }
}

export default Audio
