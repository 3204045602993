import styled from 'styled-components'
import { color, fontFamily, textAlign } from 'styled-system'
import { camelCase } from 'lodash'

const fontSize = (size, font) => {
  const sizeSwitch = {
    small: {
      monospace: ['1.75rem', '1.5rem', '1.25rem'],
      serif: ['2rem', '1.5rem', '1.25rem'],
      sansSerif: ['1.625rem', '1.5rem', '1.25rem'],
    },
    medium: {
      monospace: ['2.375rem', '2rem', '1.5rem'],
      serif: ['3rem', '2rem', '1.5rem'],
      sansSerif: ['2.375rem', '2rem', '1.5rem'],
    },
    large: {
      monospace: ['3.125rem', '2.5rem', '2rem'],
      serif: ['4.125rem', '3rem', '2rem'],
      sansSerif: ['3.375rem', '2.5rem', '2rem'],
    },
  }[camelCase(size)][camelCase(font)]

  return sizeSwitch
}

const padding = (size, font) => {
  const sizeSwitch = {
    small: {
      monospace: {
        x: ['17.5rem', '9rem', '2rem'],
        y: ['8.125rem', '5rem', '3rem'],
      },
      serif: {
        x: ['12.5rem', '9rem', '2rem'],
        y: ['11.25rem', '5rem', '3rem'],
      },
      sansSerif: {
        x: ['12.5rem', '9rem', '2rem'],
        y: ['12.5rem', '5rem', '3rem'],
      },
    },
    medium: {
      monospace: {
        x: ['17.5rem', '9rem', '2rem'],
        y: ['12.5rem', '5rem', '3rem'],
      },
      serif: {
        x: ['12.5rem', '9rem', '2rem'],
        y: ['12.75rem', '5rem', '3rem'],
      },
      sansSerif: {
        x: ['12.5rem', '9rem', '2rem'],
        y: ['15.625rem', '5rem', '3rem'],
      },
    },
    large: {
      monospace: {
        x: ['17.5rem', '9rem'],
        y: ['12.5rem', '5rem'],
      },
      serif: {
        x: ['12.5rem', '9rem'],
        y: ['12.75rem', '5rem'],
      },
      sansSerif: {
        x: ['12.5rem', '9rem'],
        y: ['12.5rem', '5rem'],
      },
    },
  }[camelCase(size)][camelCase(font)]

  const obj = { x: sizeSwitch.x, y: sizeSwitch.y }

  return obj
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  background: ${props => props.backgroundColor};
  ${color};
  border-top: ${props =>
    props.border && `1px solid ${props.theme.colors.black}`};
  border-bottom: ${props =>
    props.border && `1px solid ${props.theme.colors.black}`};
  padding: ${props => props.padding};
  // margin: -1px;
  width: 100%;
  ${props => props.fullHeight && props.fullWidth && 'min-height: 100vh'};
  ${props =>
    props.fullWidth
      ? `padding: 
        ${padding(props.size, props.font).x[0]} ${
          padding(props.size, props.font).y[0]
        }`
      : `padding: 
        calc( ${padding(props.size, props.font).x[0]} / 1.5) calc( ${
          padding(props.size, props.font).y[0]
        } / 1.5)`};
  @media only screen and (max-width: 80rem) {
    ${props =>
      props.fullWidth
        ? `padding: 
          ${padding(props.size, props.font).x[1]} ${
            padding(props.size, props.font).y[1]
          }`
        : `padding: 
          calc( ${padding(props.size, props.font).x[1]} / 1.5) calc( ${
            padding(props.size, props.font).y[1]
          } / 1.5)`};
  }
  @media only screen and (max-width: 62rem) {
    ${props => !props.fullWidth && `width: calc(100vw - 3rem);`};
  }
  @media only screen and (max-width: 48rem) {
    ${props =>
      props.fullWidth
        ? `padding: 
          ${padding(props.size, props.font).x[2]} ${
            padding(props.size, props.font).y[2]
          }`
        : `padding: 
          calc( ${padding(props.size, props.font).x[2]} / 1.5) calc( ${
            padding(props.size, props.font).y[2]
          } / 1.5)`};
  }
`

export const Text = styled.aside`
  font-size: 4rem;
  ${fontFamily};
  ${props => props.fontFamily === 'sansSerif' && 'font-weight: 300'};
  ${textAlign};
  ${props =>
    props.fullWidth
      ? `font-size: ${fontSize(props.size, props.font)[0]}`
      : `font-size: calc( ${fontSize(props.size, props.font)[0]} / 1.25)`};
  ${props => props.fontStyle === 'italic' && 'font-style: italic'};
  ${props => props.fontStyle === 'bold' && 'font-weight: bold'};
  line-height: 1.5;
  max-width: 40em;
  width: 100%;
  @media only screen and (max-width: 80rem) {
    ${props =>
      props.fullWidth
        ? `font-size: ${fontSize(props.size, props.font)[1]}`
        : `font-size: calc( ${fontSize(props.size, props.font)[1]} / 1.25)`};
  }
  @media only screen and (max-width: 48rem) {
    ${props =>
      props.fullWidth
        ? `font-size: ${fontSize(props.size, props.font)[2]}`
        : `font-size: calc( ${fontSize(props.size, props.font)[2]} / 1.25)`};
  }
`
