import React from 'react'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faArrowRight, faBars, faTimes } from '@fortawesome/pro-light-svg-icons'
import { Animate } from 'react-move'
import { easeExpInOut } from 'd3-ease'

import MenuDrawer from './menu-drawer'
import ShareDrawer from './share-drawer'
import {
  StyledButton,
  Fixed,
  PageTitle,
  Background,
  Divider,
  OutOfBounds,
  Close,
} from './menu.css'

class Menu extends React.PureComponent {
  componentDidMount() {
    const { hideMenu } = this.props
    window.addEventListener('scroll', hideMenu)
  }
  componentWillUnmount() {
    const { hideMenu } = this.props
    window.removeEventListener('scroll', hideMenu)
  }

  render() {
    const {
      menuOpen,
      shareOpen,
      toggleShare,
      toggleMenu,
      menuTitle,
      hideMenu,
    } = this.props

    return (
      <>
        {menuOpen && <Close onClick={hideMenu} />}
        <Fixed>
          <OutOfBounds />
          <Background />
          <StyledButton onClick={toggleMenu}>
            {menuOpen ? (
              <span>
                <FontAwesomeIcon
                  icon={faTimes}
                  size="1x"
                  fixedWidth
                  style={{ marginRight: '0.25rem' }}
                />Close Menu
              </span>
            ) : (
              <span>
                <FontAwesomeIcon
                  icon={faBars}
                  size="1x"
                  fixedWidth
                  style={{ marginRight: '0.25rem' }}
                />Open Menu
              </span>
            )}
          </StyledButton>
          <PageTitle>{menuTitle}</PageTitle>
          <div>
            <StyledButton onClick={toggleShare}>share</StyledButton>
          </div>
          <Animate
            show={menuOpen}
            start={{
              translateY: -100,
            }}
            enter={{
              translateY: [0],
              timing: { duration: 400, ease: easeExpInOut },
            }}
            update={{
              translateY: [0],
              timing: { duration: 400, ease: easeExpInOut },
            }}
            leave={{
              translateY: [-100],
              timing: { duration: 400, ease: easeExpInOut },
            }}
          >
            {({ translateY }) => (
              <MenuDrawer
                style={{ transform: `translateY(${translateY}%)` }}
                hideMenu={hideMenu}
              />
            )}
          </Animate>
          <Animate
            show={shareOpen}
            start={{
              translateY: -100,
            }}
            enter={{
              translateY: [0],
              timing: { duration: 400, ease: easeExpInOut },
            }}
            update={{
              translateY: [0],
              timing: { duration: 400, ease: easeExpInOut },
            }}
            leave={{
              translateY: [-100],
              timing: { duration: 400, ease: easeExpInOut },
            }}
          >
            {({ translateY }) => (
              <ShareDrawer
                location={location}
                style={{ transform: `translateY(${translateY}%)` }}
              />
            )}
          </Animate>
        </Fixed>
      </>
    )
  }
}

export default Menu
