import React from 'react'
import Link from 'gatsby-link'

import {
  Container,
  Wrapper,
  Flex,
  SectionTitle,
  Post,
  Image,
  TypeWrapper,
  Type,
  Divider,
  Title,
  PostWrapper,
  StyledLink,
} from './styles'

const Related = ({ posts }) => (
  <Container>
    <Wrapper>
      <Flex>
        <SectionTitle>Read Next</SectionTitle>
        <StyledLink to="/content/">View Everything</StyledLink>
      </Flex>
      <PostWrapper>
        {posts.map(post => (
          <Post key={post.node.id}>
            <Link
              to={
                post.node.type === 'story'
                  ? `/story/${post.node.slug}`
                  : `/context/${post.node.slug}`
              }
            >
              <Image
                sizes={post.node.thumbnailImage.sizes}
                imgStyle={{ objectFit: 'contain' }}
              />
              <div>
                <TypeWrapper pos="center">
                  <Type uppercase>{post.node.type}</Type>
                  {post.node.subject &&
                    post.node.subject.length === 1 && (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Divider />
                        <Type>{post.node.subject[0].name}</Type>
                      </div>
                    )}
                  {post.node.author &&
                    post.node.author.length === 1 && (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Divider />
                        <Type>{post.node.author[0].name}</Type>
                      </div>
                    )}
                  {post.node.author &&
                    post.node.author.length > 1 && (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Divider />
                        <Type>Multiple Contributors</Type>
                      </div>
                    )}
                </TypeWrapper>
                <Title>{post.node.title}</Title>
              </div>
            </Link>
          </Post>
        ))}
      </PostWrapper>
    </Wrapper>
  </Container>
)

export default Related
