import styled from 'styled-components'
import Img from 'gatsby-image'
import Link from 'gatsby-link'

export const Container = styled.div`
  border-top: 1px solid;
  padding-top: 6rem;
  padding-bottom: 3rem;
`

export const Wrapper = styled.div`
  max-width: 100rem;
  margin: 0 auto;
  padding: 2rem 4rem;
  @media only screen and (max-width: 48rem) {
    padding: 2rem;
  }
`

export const StyledLink = styled(Link)`
  color: inherit;
`

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid;
  padding-bottom: 1rem;
`

export const SectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.serif};
  font-weight: 300;
  font-size: 4rem;
  margin-bottom: 0;
  @media only screen and (max-width: 48rem) {
    font-size: 2.5rem;
  }
`

export const Post = styled.div`
  margin: 4rem 2rem;
  transition: 0.25s;
  width: calc((100% / 3) - 4rem);

  a {
    color: ${props => props.theme.colors.black};
    text-decoration: none;
  }
  &:hover {
    transform: scale(1.05);
  }
  @media only screen and (max-width: 62rem) {
    display: flex;
    margin: 2rem 0;
    width: 100%;
    a {
      display: flex;
      div {
        align-self: center;
      }
    }
  }
  @media only screen and (max-width: 48rem) {
    a {
      flex-direction: column;
      margin: 0 auto;
    }
  }
`

export const Image = styled(Img)`
  max-width: 22rem;
  max-height: 22rem;
  margin: 0 auto;
  @media only screen and (max-width: 62rem) {
    width: 22rem;
    height: 22rem;
    margin-right: 2rem;
  }
  @media only screen and (max-width: 48rem) {
    margin: 0;
    max-width: 16rem;
    max-height: 16rem;
  }
`

export const Title = styled.h3`
  margin: 0 auto;
  margin-top: 1rem;
  font-family: ${props => props.theme.fonts.sansSerif};
  font-size: 1.25rem;
  font-weight: 300;
  text-decoration: none;
  text-align: center;
  max-width: 18em;
  color: ${props => props.theme.colors.black};
  @media only screen and (max-width: 62rem) {
    text-align: left;
  }
  @media only screen and (max-width: 48rem) {
    text-align: center;
  }
`

export const TypeWrapper = styled.div`
  display: flex;
  margin-top: 1rem;
  align-items: center;
  justify-content: ${props => props.pos};
  @media only screen and (max-width: 62rem) {
    text-align: left;
    align-items: flex-start;
    justify-content: start;
  }
  @media only screen and (max-width: 48rem) {
    text-align: center;
    align-items: center;
    justify-content: center;
  }
`

export const Type = styled.p`
  margin: 0;
  font-family: ${props => props.theme.fonts.monospace};
  ${props => props.uppercase && 'text-transform: uppercase'};
  font-size: 0.875rem;
`

export const Divider = styled.hr`
  display: inline-block;
  height: 1px;
  width: 3rem;
  border: 0;
  margin: 0 1em;
  padding: 0;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-bottom: 1px ${props => props.theme.colors.black} solid;
  }
`

export const PostWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media only screen and (max-width: 62rem) {
    flex-direction: column;
  }
`
