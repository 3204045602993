import styled from 'styled-components'
import { fontFamily } from 'styled-system'
import Img from 'gatsby-image'
import Slider from 'react-rangeslider'

export const StyledImg = styled(Img)`
  width: 100%;
  height: 100vh;
  z-index: 0;
`

export const ImageWrap = styled.div`
  height: 100%;
  position: relative;
`
export const AudioWrapper = styled.div`
  position: ${props => (props.optionalImage ? 'absolute' : 'initial')};
  bottom: 3.5rem;
  left: 1rem;
  right: 1rem;
  padding: 1rem;
  background: ${props =>
    props.thm === 'Dark' ? props.theme.colors.black : props.theme.colors.white};
  @media only screen and (max-width: 62rem) {
    padding-right: 3rem;
  }
`

export const Type = styled.h3`
  font-weight: 400;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin-left: 3rem;
  margin-right: 0.5rem;
  ${fontFamily};
  color: ${props =>
    props.thm === 'Dark' ? props.theme.colors.white : props.theme.colors.black};
  @media only screen and (max-width: 32rem) {
    font-size: 0.875rem;
  }
`

export const Title = styled.h2`
  font-weight: 300;
  font-size: 1.875rem;
  margin-left: 3rem;
  margin-right: 0.5rem;
  ${fontFamily};
  color: ${props =>
    props.thm === 'Dark' ? props.theme.colors.white : props.theme.colors.black};
  @media only screen and (max-width: 32rem) {
    font-size: 1.5rem;
  }
`

export const Description = styled.div`
  margin-left: 3rem;
  margin-right: 0.5rem;
  max-width: 28em;
  line-height: 1.5;
  color: ${props =>
    props.thm === 'Dark' ? props.theme.colors.white : props.theme.colors.black};
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  background: ${props =>
    props.thm === 'Dark' ? props.theme.colors.black : props.theme.colors.white};
`

export const IconButton = styled.button`
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  background: none;
  border: none;
  padding: 0;
  font-size: 2.5rem;
  margin-right: 0.75rem;
  color: ${props =>
    props.thm === 'Dark' ? props.theme.colors.white : props.theme.colors.black};
`

export const StyledAudio = styled.div`
  display: flex;
  align-items: center;
`

export const AudioSeek = styled(Slider)`
  width: 100%;
  &.rangeslider {
    box-shadow: none;
    background: ${props =>
      props.thm === 'Dark'
        ? props.theme.colors.white
        : props.theme.colors.black};
  }
  &.rangeslider-horizontal {
    height: 0.5rem;
    border-radius: 0;
    & .rangeslider__fill {
      background: none;
      box-shadow: none;
    }
    & .rangeslider__handle {
      width: 0.25rem;
      height: 0.75rem;
      &::after {
        display: none;
      }
      box-shadow: none;
      background: ${props =>
        props.thm === 'Dark'
          ? props.theme.colors.black
          : props.theme.colors.white};
      border-radius: 0;
      border: none;
    }
  }
`

export const VolumeSlider = styled(Slider)`
  opacity: 0;
  bottom: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  &.rangeslider {
    position: absolute;
    box-shadow: none;
    background: ${props =>
      props.thm === 'Dark'
        ? props.theme.colors.white
        : props.theme.colors.black};
  }
  &.rangeslider-vertical {
    height: 5rem;
    width: 0.5rem;
    border-radius: 0;
    & .rangeslider__fill {
      background: none;
      box-shadow: none;
    }
    & .rangeslider__handle {
      left: -0.125rem;
      width: 0.75rem;
      height: 0.25rem;
      &::after {
        display: none;
      }
      box-shadow: none;
      background: ${props =>
        props.thm === 'Dark'
          ? props.theme.colors.black
          : props.theme.colors.white};
      border-radius: 0;
      border: none;
    }
  }
`

export const StyledMute = styled.button`
  margin: 0;
  padding: 0;
  appearance: none;
  background: none;
  border: none;
  font-size: 1rem;
  color: ${props =>
    props.thm === 'Dark' ? props.theme.colors.white : props.theme.colors.black};
`

export const VolumeWrapper = styled.div`
  position: relative;
  margin-left: 0.75rem;
  &:hover {
    .volume-slider {
      opacity: 1;
    }
  }
`
