import React from 'react'

import { Navigation, NavInfo, NavButton } from './styles'

const SidebarNav = props => (
  <Navigation
    fontSize={1}
    fontFamily="monospace"
    color={
      props.items && props.items[props.currentSlide]
        ? props.items[props.currentSlide].backgroundColor
        : 'Light'
    }
  >
    <NavButton onClick={props.previousSlide}>Previous</NavButton>
    <NavInfo>
      {props.currentSlide + 1} / {props.slideCount}
    </NavInfo>
    <NavButton onClick={props.nextSlide}>Next</NavButton>
  </Navigation>
)

export default SidebarNav
