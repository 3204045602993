import styled from 'styled-components'
import { color, maxWidth, space } from 'styled-system'

export const Section = styled.div`
  ${color};
  padding: 8rem;
  border-bottom: 1px solid;
  ${space};
  @media only screen and (max-width: 48rem) {
    padding: 6rem 3rem;
  }
`

export const Wrapper = styled.div`
  ${maxWidth};
  margin: 0 auto;
`

export const Text = styled.p`
  font-family: 'GT Super Display', serif;
  font-size: ${props => (props.large ? '4.5rem' : '3rem')};
  text-align: ${props => (props.large ? 'center' : 'left')};
  max-width: ${props => (props.large ? '14em' : '20em')};
  margin: 0 auto;
  &:not(:last-child) {
    margin-bottom: 1em;
  }
  @media only screen and (max-width: 62rem) {
    font-size: ${props => (props.large ? '2.5rem' : '2rem')};
  }
`

export const Name = styled.div`
  font-weight: bold;
  margin: 0;
  ${props =>
    props.press &&
    `
  font-family: ${props.theme.fonts.sansSerif};
  font-weight: 400;
  background-size: 1px 1em;
  text-shadow:
  -2px -2px white,
  -2px 2px white,
  2px -2px white,
  2px 2px white;
  box-shadow:
    inset 0 -0.175em white,
    inset 0 -0.2em #000;
  display: inline;
  `};
`

export const Role = styled.div`
  font-size: 1.25rem;
  font-family: ${props => props.theme.fonts.monospace};
  margin: 0;
  margin-top: 0.5rem;
  margin-bottom: 3rem;
`

export const StyledLink = styled.a`
  color: inherit;
  font-weight: bold;
  background-image: linear-gradient(black, black);
  background-position: 0 1.05em;
  background-repeat: repeat-x;
  background-size: 1px 1px;
`

export const Chunk = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8rem;
  padding-bottom: 6rem;
  &:not(:last-child) {
    border-bottom: 1px solid;
  }
  p {
    font-size: 2rem;
    font-family: ${props => props.theme.fonts.serif};
    margin-top: 0;
    width: 100%;
    line-height: 1.4;
  }
  div {
    width: 100%;
  }
  h3 {
    width: 33%;
    margin-top: 0.25rem;
    margin-right: 1rem;
    flex: none;
    font-size: 1.25rem;
    font-weight: 400;
    font-family: ${props => props.theme.fonts.monospace};
    text-transform: uppercase;
  }
  @media only screen and (max-width: 62rem) {
    flex-direction: column;
    h3 {
      margin-bottom: 6rem;
      font-size: 1.5rem;
      text-align: center;
      width: 100%;
      margin-right: 0;
    }
    p {
      font-size: 1.5rem;
    }
  }
`

export const Person = styled.div`
  font-size: 2rem;
  font-family: ${props => props.theme.fonts.serif};
  margin-top: 0;
  width: 100%;
  a {
    color: initial;
    text-decoration: none;
  }
  @media only screen and (max-width: 62rem) {
    text-align: center;
  }
`
