import React from 'react'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/pro-regular-svg-icons'

import {
  StyledImg,
  LinkWrapper,
  Container,
  Type,
  Title,
  Description,
  StyledLink,
} from './styles'

const InternalLink = ({
  title,
  description,
  backgroundColor,
  internalLink,
}) => (
  <Container>
    <LinkWrapper>
      {internalLink && <StyledImg sizes={internalLink.thumbnailImage.sizes} />}
      {internalLink && (
        <Type fontFamily="monospace" color="white">
          {internalLink.type}
        </Type>
      )}
      {title && (
        <Title fontFamily="serif" color="white">
          <FontAwesomeIcon
            fixedWidth
            icon={faLink}
            size="1x"
            style={{ marginRight: '0.75rem' }}
          />
          {title}
        </Title>
      )}
      {description && (
        <Description
          dangerouslySetInnerHTML={{
            __html: description.childMarkdownRemark.html,
          }}
        />
      )}
      {console.log(internalLink)}
      {console.log('internalLink')}
      {internalLink && (
        <StyledLink to={`/${internalLink.type}/${internalLink.slug}`}>
          Read More
        </StyledLink>
      )}
    </LinkWrapper>
  </Container>
)

export default InternalLink
