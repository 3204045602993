import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import ChangeTitle from 'containers/change-title'
import { Container, Content, Block, ToggleBack } from './styles'

class CollaboratorTemplate extends React.PureComponent {
  render() {
    const {
      name,
      title,
      group,
      field,
      bio,
      image,
    } = this.props.data.contentfulCollaborator

    return (
      <Container>
        <ChangeTitle title={name} />
        <div className="wrapper">
          <div>All Contributers</div>
          <div style={{ display: 'flex' }}>
            <ul style={{ width: '100%' }}>
              {name && <li>{name}</li>}
              {title && <li>{title}</li>}
              {group && <li>{group}</li>}
              {field && <li>{field}</li>}
              {bio && (
                <li>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: bio.childMarkdownRemark.html,
                    }}
                  />
                </li>
              )}
            </ul>
            {image && <Img fluid={image.fluid} style={{ width: '100%' }} />}
          </div>
          <div>Credits</div>
        </div>
      </Container>
    )
  }
}

export default CollaboratorTemplate

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query CollaboratorBySlug($id: String!) {
    contentfulCollaborator(id: { eq: $id }) {
      name
      title
      group
      field
      image {
        id
        fluid(maxWidth: 1600) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      bio {
        id
        childMarkdownRemark {
          id
          html
        }
      }
    }
  }
`
