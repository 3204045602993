import React from 'react'
import { filter, findIndex, orderBy } from 'lodash'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons'

import {
  Chunk,
  Wrapper,
  Person,
  Name,
  Title,
  IconWrapper,
  TextWrapper,
} from './styles'

const orderTeam = people => {
  const claireIndex = findIndex(people, {
    node: { id: 'c5L9QpvoJR62OmauisWU0EI' },
  })
  people.splice(0, 0, people.splice(claireIndex, 1)[0])

  const saraIndex = findIndex(people, {
    node: { id: 'EWEWArdbX2Y4aqMmIWIWa' },
  })
  people.splice(0, 0, people.splice(saraIndex, 1)[0])

  const michelineIndex = findIndex(people, {
    node: { id: 'c1Kcrt9j67SMoQKKQ4CUwQw' },
  })
  people.splice(0, 0, people.splice(michelineIndex, 1)[0])

  const jenIndex = findIndex(people, {
    node: { id: 'NSAyYtw9Gue0YYKAWu8kG' },
  })
  people.splice(people.length, 0, people.splice(jenIndex, 1)[0])

  const trevorIndex = findIndex(people, {
    node: { id: 'c6j2rgqjmqAoWuc8WGOQUse' },
  })
  people.splice(people.length, 0, people.splice(trevorIndex, 1)[0])

  return people
}

const Content = ({ people }) => (
  <div>
    <Wrapper bg="white" color="black">
      <Chunk>
        <h3>NASP Team</h3>
        <div>
          {orderTeam(
            filter(
              people,
              o =>
                o.node.group === 'Team' &&
                o.node.id !== 'c2exWuoyJFyCYqgsoyK0g22'
            )
          ).map(person => (
            <Person key={person.node.id}>
              <TextWrapper>
                <Name>{person.node.name}</Name>
                <Title>{person.node.title}</Title>
              </TextWrapper>

              {(person.node.bio ||
                person.node.image ||
                person.node.linkWebsite ||
                person.node.linkTwitter ||
                person.node.linkInstagram) && (
                <IconWrapper>
                  <FontAwesomeIcon icon={faArrowRight} size="2x" />
                </IconWrapper>
              )}
            </Person>
          ))}
        </div>
      </Chunk>
      <Chunk>
        <h3>Artists & Writers</h3>
        <div>
          {orderBy(
            filter(people, o => o.node.group === 'Artist'),
            [p => p.node.name.toLowerCase()],
            ['asc']
          ).map(person => (
            <Person key={person.node.id}>
              <TextWrapper>
                <Name>{person.node.name}</Name>
                <Title>{person.node.field}</Title>
              </TextWrapper>
              {(person.node.bio ||
                person.node.image ||
                person.node.linkWebsite ||
                person.node.linkTwitter ||
                person.node.linkInstagram) && (
                <IconWrapper>
                  <FontAwesomeIcon icon={faArrowRight} size="2x" />
                </IconWrapper>
              )}
            </Person>
          ))}
        </div>
      </Chunk>
      <Chunk>
        <h3>Contributors</h3>
        <div>
          {orderBy(
            filter(people, o => o.node.group === 'Contributor'),
            [p => p.node.name.toLowerCase()],
            ['asc']
          ).map(person => (
            <Person key={person.node.id}>
              <TextWrapper>
                <Name>{person.node.name}</Name>
                <Title>{person.node.field}</Title>
              </TextWrapper>
              {(person.node.bio ||
                person.node.image ||
                person.node.linkWebsite ||
                person.node.linkTwitter ||
                person.node.linkInstagram) && (
                <IconWrapper>
                  <FontAwesomeIcon icon={faArrowRight} size="2x" />
                </IconWrapper>
              )}
            </Person>
          ))}
        </div>
      </Chunk>
    </Wrapper>
  </div>
)

export default Content
