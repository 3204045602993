import styled from 'styled-components'

export const Md = styled.div`
  margin: 8rem 10rem 8rem 8rem;
  max-width: 35rem;
  font-size: 1.25rem;
  line-height: 1.5;

  h1 {
    font-family: ${props => props.theme.fonts.serif};
    font-weight: 300;
    font-size: 3.25rem;
    line-height: 1.3;
  }
  h2 {
    font-family: ${props => props.theme.fonts.sans};
    font-weight: 400;
    font-size: 2.375rem;
    line-height: 1.3;
  }
  h3 {
    font-family: ${props => props.theme.fonts.sans};
    font-weight: 500;
    font-size: 1.75rem;
    line-height: 1.3;
  }

  @media only screen and (max-width: 80rem) {
    margin: 6rem 5rem 6rem 4rem;
  }
  @media only screen and (max-width: 48rem) {
    h1 {
      font-size: 2.75rem;
    }
    h2 {
      font-size: 2rem;
    }
    h3 {
      font-size: 1.5rem;
    }
  }
  @media only screen and (max-width: 32rem) {
    margin: 4rem 1.5rem 4rem 1.5rem;
    font-size: 1.1rem;
    h1 {
      font-size: 2.25rem;
    }
    h2 {
      font-size: 1.75rem;
    }
    h3 {
      font-size: 1.25rem;
    }
  }
`

export const Err = styled.div``
