import React from 'react'
import VimeoPlayer from 'react-player/lib/players/Vimeo'

import { Container, StyledImg, Caption, PlayerWrapper } from './styles'

const ImageVideo = ({
  title,
  fullHeightCrop,
  image,
  backgroundColor,
  backgroundVideo,
}) => (
  <Container thm={backgroundColor}>
    {/* swap to video-react with vimeo PRO to allow fullHeightCrop */}
    {image &&
      !backgroundVideo && (
        <StyledImg fullHeightCrop={fullHeightCrop} sizes={image.sizes} />
      )}
    {backgroundVideo && (
      <PlayerWrapper>
        <VimeoPlayer
          url={backgroundVideo}
          className="react-player"
          playing
          muted
          width="auto"
          height="100vh"
          config={{ vimeo: { playerOptions: { background: true } } }}
        />
      </PlayerWrapper>
    )}
    {title && (
      <Caption fontSize={1} fontFamily="monospace" thm={backgroundColor}>
        {title}
      </Caption>
    )}
  </Container>
)

export default ImageVideo
