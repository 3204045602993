// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-story-story-jsx": () => import("/opt/build/repo/src/templates/Story/story.jsx" /* webpackChunkName: "component---src-templates-story-story-jsx" */),
  "component---src-templates-context-context-jsx": () => import("/opt/build/repo/src/templates/Context/context.jsx" /* webpackChunkName: "component---src-templates-context-context-jsx" */),
  "component---src-templates-collaborator-collaborator-jsx": () => import("/opt/build/repo/src/templates/collaborator/collaborator.jsx" /* webpackChunkName: "component---src-templates-collaborator-collaborator-jsx" */),
  "component---src-pages-about-jsx": () => import("/opt/build/repo/src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-action-jsx": () => import("/opt/build/repo/src/pages/action.jsx" /* webpackChunkName: "component---src-pages-action-jsx" */),
  "component---src-pages-collaborators-jsx": () => import("/opt/build/repo/src/pages/collaborators.jsx" /* webpackChunkName: "component---src-pages-collaborators-jsx" */),
  "component---src-pages-content-jsx": () => import("/opt/build/repo/src/pages/content.jsx" /* webpackChunkName: "component---src-pages-content-jsx" */),
  "component---src-pages-index-jsx": () => import("/opt/build/repo/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */)
}

