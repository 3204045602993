import styled from 'styled-components'
import Img from 'gatsby-image'
import { width } from 'styled-system'

export const StyledImg = styled(Img)`
  width: 100%;
  height: 100%;
  ${width};
  ${props => props.fullWidth && 'max-height: 100vh;'};
  ${props =>
    props.pos === 'Center' ? 'margin: 0 auto' : `float: ${props.pos}`};
`

export const Caption = styled.div`
  position: absolute;
  background: rgb(0, 0, 0, 0.6);
  color: white;
  bottom: ${props => (props.padding ? '3rem' : '0')};
  right: ${props => (props.padding ? '3rem' : '0')};
  left: ${props => (props.padding ? '3rem' : '0')};
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  div {
    padding: 0.15rem 1rem;
  }
  p {
    margin: 0;
  }
`

export const Container = styled.div`
  position: relative;
  ${props => (!props.fullWidth ? 'width: 65vw;' : 'width: 100%')};
  ${props => props.padding && 'padding: 3rem'};
  &::after {
    content: '';
    clear: both;
    display: table;
  }
  ${props =>
    !props.fullWidth &&
    `
  @media only screen and (max-width: 62rem) {
    width: calc(100vw - 3rem);
  }
  `};
`
