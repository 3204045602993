import styled from 'styled-components'
import { color, maxWidth, space } from 'styled-system'

export const Section = styled.div`
  ${color};
  padding: 6rem;
  border-bottom: 1px solid;
  ${space};
  @media only screen and (max-width: 48rem) {
    padding: 6rem 3rem;
  }
`

export const Wrapper = styled.div`
  ${maxWidth};
  ${color};
  margin: 0 auto;
`

export const Text = styled.p`
  font-family: 'GT Super Display', serif;
  font-size: 2.5rem;
  text-align: ${props => (props.large ? 'center' : 'left')};
  max-width: ${props => (props.large ? '18em' : '20em')};
  margin: 0 auto;
  &:not(:last-child) {
    margin-bottom: 1em;
  }
  @media only screen and (max-width: 62rem) {
    font-size: 2rem;
  }
`

export const StyledLink = styled.a`
  color: inherit;
  font-weight: bold;
  background-image: linear-gradient(black, black);
  background-position: 0 1.05em;
  background-repeat: repeat-x;
  background-size: 1px 1px;
`

export const Person = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 3rem 6rem;
  width: 100%;
  border-bottom: 1px solid;
  @media only screen and (max-width: 48rem) {
    padding: 2rem 3rem;
  }
`

export const Name = styled.div`
  font-size: 2.25rem;
  font-family: ${props => props.theme.fonts.serif};
  margin: 0;
  @media only screen and (max-width: 62rem) {
    font-size: 1.5rem;
  }
`

export const Title = styled.div`
  font-size: 1rem;
  font-family: ${props => props.theme.fonts.monospace};
  margin: 0;
  margin-top: 0.5rem;
`

export const Chunk = styled.div`
  display: flex;
  flex-direction: column;
  h3 {
    margin: 0;
    padding-top: 8rem;
    padding-bottom: 8rem;
    flex: none;
    text-align: center;
    font-size: 1.25rem;
    font-weight: 400;
    font-family: ${props => props.theme.fonts.monospace};
    text-transform: uppercase;
    border-bottom: 1px solid;
  }
  @media only screen and (max-width: 62rem) {
    h3 {
      padding-top: 4rem;
      padding-bottom: 4rem;
    }
  }
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
export const IconWrapper = styled.div`
  justify-self: end;
  align-self: center;
  .svg-inline--fa {
    display: initial;
  }
`
