import React from 'react'
import { withScriptjs, withGoogleMap, GoogleMap } from 'react-google-maps'

import { Container } from './styles'
import mapStylesLight from './mapstylesLight.json'
import mapStylesDark from './mapstylesDark.json'

const MapWrap = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={props.zoom || 12}
      defaultCenter={props.center}
      defaultOptions={{
        mapTypeId: props.mapStyle ? 'satellite' : 'roadmap',
        styles: props.color,
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_CENTER,
        },
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
      }}
    />
  ))
)

const MapComp = ({ locations, zoom, mapStyle, backgroundColor }) => (
  <Container>
    <MapWrap
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBzVjkoe4rxJUJzxO5T_jmnlfeCSmtCKO4&v=3.exp&libraries=geometry,drawing,places"
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `100vh` }} />}
      mapElement={<div style={{ height: `100%` }} />}
      center={{
        lat: locations[0].location.lat,
        lng: locations[0].location.lon,
      }}
      zoom={zoom}
      mapStyle={mapStyle}
      color={backgroundColor === 'Dark' ? mapStylesDark : mapStylesLight}
    />
  </Container>
)

export default MapComp
