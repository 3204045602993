import React, { Component } from 'react'
import Sticky from 'react-sticky-el'
import Carousel from 'nuka-carousel'
import { Element } from 'react-scroll'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  faHeadphones,
  faMap,
  faImage,
  faBook,
  faLink,
  faExternalLink,
} from '@fortawesome/pro-solid-svg-icons'

import { filter } from 'lodash'

import MapComp from '../SidebarComponents/MapComp/MapComp'
import ImageVideo from '../SidebarComponents/ImageVideo/ImageVideo'
import Audio from '../SidebarComponents/Audio/Audio'
import Definition from '../SidebarComponents/Definition/Definition'
import ExternalLink from '../SidebarComponents/ExternalLink/ExternalLink'
import InternalLink from '../SidebarComponents/InternalLink/InternalLink'
import SidebarNav from './SidebarNav'
import { Slide, SidebarWrapper, SidebarInnerWrapper, Toggle } from './styles'

class Sidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      manualSlide: false,
    }
    this.getIcon = this.getIcon.bind(this)
  }
  getIcon(type) {
    if (type === 'ContentfulComponentSidebarMap') return faMap
    if (type === 'ContentfulComponentSidebarImageOrVideo') return faImage
    if (type === 'ContentfulComponentSidebarAudio') return faHeadphones
    if (type === 'ContentfulComponentSidebarDefinition') return faBook
    if (type === 'ContentfulComponentSidebarExternalLink') return faExternalLink
    if (type === 'ContentfulComponentSidebarInternalLink') return faLink
    return faHeadphones
  }

  componentWillReceiveProps(nextProps) {
    console.log('next')
    console.log(nextProps)
  }

  render() {
    const { index, sidebarContent, toggle, isExpanded } = this.props
    const sidebarItems = filter(
      sidebarContent,
      o => o.__typename !== 'ContentfulComponentSidebarBreak'
    )
    return (
      <SidebarWrapper>
        <Sticky boundaryElement=".block" hideOnBoundaryHit={false}>
          <Element name={`sidebar-${index}`} />
          <SidebarInnerWrapper isExpanded={isExpanded}>
            <Carousel
              renderBottomCenterControls={props =>
                props.slideCount > 1 && (
                  <SidebarNav items={sidebarItems} {...props} />
                )
              }
              renderTopCenterControls={() => null}
              renderCenterLeftControls={() => null}
              renderCenterRightControls={({ currentSlide }) => (
                <Toggle
                  className="toggle"
                  isExpanded={isExpanded}
                  onClick={() => toggle(`sidebar-${index}`)}
                >
                  <span>Expand</span>
                  <FontAwesomeIcon
                    icon={
                      sidebarItems[currentSlide] &&
                      this.getIcon(sidebarItems[currentSlide].__typename)
                    }
                    size="sm"
                  />
                </Toggle>
              )}
              slideIndex={index}
              dragging={false}
              style={{
                position: 'relative',
                borderRight: '1px solid black',
                height: '100vh',
              }}
            >
              {sidebarItems.map(item => (
                <Slide>
                  {item.__typename === 'ContentfulComponentSidebarMap' && (
                    <MapComp {...item} />
                  )}
                  {item.__typename ===
                    'ContentfulComponentSidebarImageOrVideo' && (
                    <ImageVideo {...item} />
                  )}
                  {item.__typename === 'ContentfulComponentSidebarAudio' && (
                    <Audio {...item} />
                  )}
                  {item.__typename ===
                    'ContentfulComponentSidebarDefinition' && (
                    <Definition {...item} />
                  )}
                  {item.__typename ===
                    'ContentfulComponentSidebarExternalLink' && (
                    <ExternalLink {...item} />
                  )}
                  {item.__typename ===
                    'ContentfulComponentSidebarInternalLink' && (
                    <InternalLink {...item} />
                  )}
                </Slide>
              ))}
            </Carousel>
          </SidebarInnerWrapper>
        </Sticky>
      </SidebarWrapper>
    )
  }
}

export default Sidebar
