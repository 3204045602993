import React from 'react'
import VimeoPlayer from 'react-player/lib/players/Vimeo'

import { StyledImg, Container, Caption } from './styles'

const ImageOrVideo = ({
  image,
  video,
  fullWidth,
  imagePosition,
  imagePadding,
  imageWidth,
  optionalCredit,
  optionalCaption,
}) => (
  <Container padding={imagePadding} fullWidth={fullWidth} isImage={!video}>
    {image &&
      !video && (
        <StyledImg
          width={imageWidth}
          pos={imagePosition}
          sizes={image[0].sizes}
          fullWidth={fullWidth}
        />
      )}
    {video && (
      <VimeoPlayer
        url={video}
        className="react-player"
        playing
        width="100vw"
        height="56vw"
        style={{ maxHeight: '80vh' }}
        config={{ vimeo: { playerOptions: { background: false } } }}
      />
    )}
    <Caption padding={imagePadding}>
      {optionalCaption && (
        <div
          dangerouslySetInnerHTML={{
            __html: optionalCaption.childMarkdownRemark.html,
          }}
        />
      )}
      {optionalCredit && <div>Credit: {optionalCredit.name}</div>}
    </Caption>
  </Container>
)

export default ImageOrVideo
