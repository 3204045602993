import styled from 'styled-components'
import Img from 'gatsby-image'
import { fontSize, fontFamily } from 'styled-system'

export const Container = styled.div`
  background: ${props =>
    props.thm === 'Dark' ? props.theme.colors.black : props.theme.colors.white};
`

export const StyledImg = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  ${props =>
    !props.fullHeightCrop &&
    `img {
      object-fit: contain !important;
    }`};
`

export const PlayerWrapper = styled.div`
  height: 100vh;
  overflow: hidden;
  position: relative;
  .react-player {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      height: 100% !important;
      width: 177.77777778vh !important;
      min-width: 100%;
      min-height: 56.25vw;
    }
  }
`

export const Caption = styled.div`
  position: absolute;
  bottom: 2.5rem;
  padding: 1rem;
  background: ${props =>
    props.thm === 'Light' ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.4)'};
  color: ${props =>
    props.thm === 'Light'
      ? props.theme.colors.white
      : props.theme.colors.white};
  width: 100%;
  ${fontSize};
  ${fontFamily};
`
