import React from 'react'
import { size } from 'lodash'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons'

import {
  StyledImg,
  LinkWrapper,
  Container,
  Type,
  Title,
  Description,
  StyledLink,
} from './styles'

const ExternalLink = ({
  title,
  description,
  backgroundColor,
  optionalImage,
  externalLink,
}) => (
  <Container>
    {optionalImage && <StyledImg sizes={optionalImage.sizes} />}
    <LinkWrapper thm={backgroundColor} optionalImage={size(optionalImage)}>
      <Type thm={backgroundColor} fontFamily="monospace">
        Link
      </Type>
      {title && (
        <Title thm={backgroundColor} fontFamily="serif">
          <FontAwesomeIcon
            fixedWidth
            icon={faExternalLink}
            size="1x"
            style={{ marginRight: '0.75rem' }}
          />
          {title}
        </Title>
      )}
      {description && (
        <Description
          thm={backgroundColor}
          dangerouslySetInnerHTML={{
            __html: description.childMarkdownRemark.html,
          }}
        />
      )}
      {externalLink && (
        <StyledLink
          href={externalLink}
          thm={backgroundColor}
          target="_blank"
          rel="noopener noreferrer"
        >
          Read More
        </StyledLink>
      )}
    </LinkWrapper>
  </Container>
)

export default ExternalLink
