import React from 'react'
import { findIndex } from 'lodash'

import { Section, Chunk, Wrapper, Person, Name, Role } from './styles'

const orderPeople = people => {
  // filter Jen and Trevor out
  const filtered = people.filter(
    i =>
      i.node.id !== 'NSAyYtw9Gue0YYKAWu8kG' &&
      i.node.id !== 'c6j2rgqjmqAoWuc8WGOQUse'
  )

  const claireIndex = findIndex(filtered, {
    node: { id: 'c5L9QpvoJR62OmauisWU0EI' },
  })
  filtered.splice(0, 0, filtered.splice(claireIndex, 1)[0])

  const saraIndex = findIndex(filtered, {
    node: { id: 'EWEWArdbX2Y4aqMmIWIWa' },
  })
  filtered.splice(0, 0, filtered.splice(saraIndex, 1)[0])

  const michelineIndex = findIndex(filtered, {
    node: { id: 'c1Kcrt9j67SMoQKKQ4CUwQw' },
  })
  filtered.splice(0, 0, filtered.splice(michelineIndex, 1)[0])

  const dvIndex = findIndex(filtered, {
    node: { id: 'c2exWuoyJFyCYqgsoyK0g22' },
  })
  filtered.splice(filtered.length, 0, filtered.splice(dvIndex, 1)[0])

  return filtered
}

const Content = ({ people, press }) => (
  <div>
    <Section bg="white" color="black">
      <Wrapper maxWidth="80rem">
        <Chunk>
          <h3>Our Mission</h3>
          <div>
            <p>
              NASP’s mission is to foster humane and substantive dialogue around
              the complexities of migration, US immigration and asylum laws, and
              human rights concerns of new immigrants. We believe that stories
              have the power to illuminate, to educate, and to increase empathy.
              Stories create change.
            </p>
            <p>
              Our current project,{' '}
              <i>
                Welcome Children: Voices of the Central American Refugee Crisis
              </i>{' '}
              focuses on unaccompanied Central American minors who journeyed
              thousands of miles to reach the U.S. They tell their stories in
              their own words while legal, social science and policy experts
              provide perspective, up-to-date data and detailed context. We hope
              to foster greater understanding about the ongoing humanitarian
              crises in Central America.
            </p>
          </div>
        </Chunk>
        <Chunk>
          <h3>NASP Team</h3>
          <div>
            {orderPeople(people).map(person => (
              <Person key={person.node.id}>
                <Name>{person.node.name}</Name>
                <Role>{person.node.title}</Role>
              </Person>
            ))}
          </div>
        </Chunk>
        <Chunk>
          <h3>NASP Press</h3>
          <div>
            {press.map(item => (
              <Person key={item.node.id}>
                <a
                  href={item.node.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Name press>{item.node.title}</Name>
                  <Role>{item.node.organization}</Role>
                </a>
              </Person>
            ))}
          </div>
        </Chunk>
      </Wrapper>
    </Section>
  </div>
)

export default Content
