import React from 'react'
import { lowerCase } from 'lodash'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons'

import {
  Container,
  HeadlineImage,
  HeadlineText,
  Title,
  Type,
  Subject,
  SubjectText,
  IconWrapper,
  Disclaimer,
} from './styles'

const Headline = ({ headline, type, title, author, subject }) => (
  <Container fullHeight={headline.height} color={headline.color}>
    {headline.backgroundImage && (
      <HeadlineImage
        width={headline.textboxWidth}
        sizes={headline.backgroundImage.sizes}
      />
    )}
    <HeadlineText
      width={headline.textboxWidth}
      textAlignment={headline.textAlignment}
      pos={headline.textboxPosition}
    >
      <Type size={lowerCase(headline.textSize)}>{type}</Type>
      <Title
        className="section-headline"
        fontFamily="serif"
        size={lowerCase(headline.textSize)}
        pos={headline.textAlignment}
      >
        {title}
      </Title>
      {subject
        ? subject.map(({ id, name, age, location }) => (
          <Subject pos={headline.textAlignment} key={id}>
            <SubjectText name size={lowerCase(headline.textSize)}>
              {name}, {age}
            </SubjectText>
            <br />
            <SubjectText size={lowerCase(headline.textSize)}>
              {location.name}
            </SubjectText>
          </Subject>
          ))
        : type === 'story' && <div />}
      {author
        ? author.map(({ id, name, title }) => (
          <Subject pos={headline.textAlignment} key={id}>
            <SubjectText name>{name}</SubjectText>
            <br />
            <SubjectText>{title}</SubjectText>
          </Subject>
          ))
        : type !== 'story' && <div />}
    </HeadlineText>
    {type === 'story' && (
      <IconWrapper
        pos={headline.textboxPosition === 'Left' && headline.backgroundImage}
      >
        <FontAwesomeIcon icon={faInfoCircle} size="lg" />
        <Disclaimer color={headline.color}>
          NOTE: All the children’s names have been changed to protect their
          identities. NASP stories about children are not matched with their
          portraits.
        </Disclaimer>
      </IconWrapper>
    )}
  </Container>
)

export default Headline
