import styled from 'styled-components'
import { fontSize, fontFamily } from 'styled-system'

export const Slide = styled.div`
  height: 100vh;
  position: relative;
`

export const Navigation = styled.div`
  display: flex;
  justify-content: center;
  width: 35vw;
  padding: 0.75rem 1rem;
  ${fontSize};
  ${fontFamily};
  ${props =>
    props.color === 'Light'
      ? `
    background: ${props.theme.colors.white};
    color: ${props.theme.colors.black};
    border-top: 1px solid;
    border-right: 1px solid;
  `
      : `
  background: ${props.theme.colors.black};
  color: ${props.theme.colors.white};
  border: 1px solid ${props.theme.colors.black};
`};
  @media only screen and (max-width: 62rem) {
    width: calc(100vw - 3rem);
    max-width: 35rem;
  }
`

export const NavInfo = styled.div`
  margin: 0 1rem;
`
export const NavButton = styled.button`
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  text-decoration: underline;
`

export const Text = styled.p`
  font-family: 'GT Super Display', serif;
  font-size: 3rem;
  font-weight: 400;
  max-width: 20em;
  margin: 0 auto;
  &:not(:last-child) {
    margin-bottom: 1em;
  }
`

export const Chunk = styled.div`
  display: flex;
  h3 {
    width: 25%;
    flex: none;
  }
`

export const SidebarWrapper = styled.div`
  width: 35vw;
  flex: none;
  @media only screen and (max-width: 62rem) {
    width: calc(100vw - 3rem);
    max-width: 35rem;
  }
`

export const SidebarInnerWrapper = styled.div`
  width: 35vw;
  height: 100vh;
  background: #111;
  color: ${props => props.theme.colors.white};
  position: relative;
  @media only screen and (max-width: 62rem) {
    width: calc(100vw - 3rem);
    max-width: 35rem;
  }
  .toggle {
    ${props => props.isExpanded && `display: none;`};
  }
`

export const Toggle = styled.div`
  padding: 0.75rem 1.5rem;
  transform-origin: 50% 50%;
  transform: translateX(50%) rotate(-90deg) translateY(-50%);
  background: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.black};
  border: ${props => props.theme.colors.black};
  display: none;
  z-index: 10;
  span {
    margin-right: 0.75rem;
  }
  @media only screen and (max-width: 62rem) {
    ${props => !props.isExpanded && `display: block`};
  }
`
